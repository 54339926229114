import { IContactBE, IContactAddressBE, ICaseLimitedBE, IContactDistributionTypeBE, IContactNotesBE, IContactPhoneBE, IContactPaymentTermBE, IContactConnectionBE, ICountryBE, ICurrencyCodeBE, IUserLimitedBE } from "@datalex-software-as/datalex-client"
import { EmptyGuid } from "../util/RandomId"

interface ContactConstructor {
    ContactSectorId: string
    FirstName: string | null
    LastName: string | null
    Name: string | null
    Country: ICountryBE
}


export class NewContactBE implements IContactBE {
    IsNew!: boolean
    IsDeleted!: boolean
    Id!: string
    ContactSectorId!: string | null
    Name!: string
    FirstName!: string
    LastName!: string
    Name2!: string | null
    PseudoName!: string | null
    Number!: number | null
    OrganizationNumber!: string | null
    NationalIdentityNumber!: string | null
    BusinessSectorId!: string | null
    OrganizationalStructureId!: string | null
    Occupation!: string | null
    Gender!: boolean | null
    ShortForm!: string | null
    Title!: string | null
    OurCustomerNumber!: string | null
    ResponsibleUserId!: string | null
    CountryId!: string | null
    BankAccountNumber!: string | null
    CurrencyCodeId!: string | null
    VATTypeId!: string | null
    VatObligated!: boolean | null
    eInvoiceContact!: boolean | null
    eInvoiceId!: string | null
    EmailAddress!: string | null
    WebAddress!: string | null
    PublicCompany!: boolean | null
    IBANBankName!: string | null
    IBANBankAddress!: string | null
    IBANBankCountryId!: string | null
    IBANBankState!: string | null
    IBANAccountNumber!: string | null
    IBANBankPostalAddressId!: string | null
    IBANIsSwift!: boolean | null
    IBANSwiftClearingCode!: string | null
    DateOfBirth!: string | null
    EHFInvoice!: boolean | null
    EHFInvoiceType!: string | null
    Supplier!: boolean | null
    IsUser!: boolean | null
    IsCompany!: boolean | null
    AccountsId!: string | null
    Timestamp!: number[] | null
    Addresses!: IContactAddressBE[] | null
    Phones!: IContactPhoneBE[] | null
    ContactNotes!: IContactNotesBE[] | null
    Cases!: ICaseLimitedBE[] | null
    ContactDistributions!: IContactDistributionTypeBE[] | null
    Connections!: IContactConnectionBE[] | null
    ContactPaymentTerms!: IContactPaymentTermBE[] | null
    ClientResponsible!: IUserLimitedBE | null
    Country!: ICountryBE | null
    CurrencyCode!: ICurrencyCodeBE | null
    EmailAddressInvoice!: string | null
    EmailInvoice!: boolean | null
    InvoiceMerge!: boolean | null
    Digipost!: boolean | null
    DigipostInvoice!: boolean | null
    OriginCode!: string | null


    constructor({ FirstName, LastName, Name, ContactSectorId, Country }: ContactConstructor) {
        this.Id = EmptyGuid;
        this.IsNew = true;
        this.IsDeleted = false;
        this.VatObligated = true;
        this.FirstName = FirstName ?? "";
        this.LastName = LastName ?? "";
        this.Name = Name ? Name : `${LastName} ${FirstName}`;
        this.Name2 = null;
        this.PseudoName = null;
        this.ContactSectorId = ContactSectorId;
        this.Number = 0;
        this.AccountsId = null;
        this.BankAccountNumber = null;
        this.BusinessSectorId = null;
        this.Country = Country;
        this.CountryId = Country.Id;
        this.CurrencyCodeId = null;
        this.DateOfBirth = null;
        this.Digipost = false
        this.DigipostInvoice = null;
        this.EHFInvoice = null;
        this.eInvoiceContact = false;
        this.eInvoiceId = null;
        this.EmailAddress = null;
        this.EmailAddressInvoice = null;
        this.EmailInvoice = null;
        this.Gender = null;
        this.IBANAccountNumber = null;
        this.IBANBankAddress = null;
        this.IBANBankCountryId = null;
        this.IBANBankName = null;
        this.IBANBankPostalAddressId = null;
        this.IBANBankState = null;
        this.IBANIsSwift = null;
        this.IBANSwiftClearingCode = null;
        this.InvoiceMerge = null;
        this.IsCompany = false;
        this.IsUser = false;
        this.NationalIdentityNumber = null;
        this.Occupation = null;
        this.OrganizationalStructureId = null;
        this.OrganizationNumber = null;
        this.OurCustomerNumber = null;
        this.PseudoName = null;
        this.PublicCompany = false
        this.ResponsibleUserId = null;
        this.Title = null;
        this.VatObligated = true;
        this.VATTypeId = null;
        this.WebAddress = null;
        this.ShortForm = null;
        this.EHFInvoiceType = null;
        this.Supplier = null;
        this.Timestamp = null;
        this.Addresses = [];
        this.Phones = [];
        this.ContactNotes = [];
        this.Cases = [];
        this.ContactDistributions = [];
        this.Connections = [];
        this.ContactPaymentTerms =  [];
        this.ClientResponsible = null;
        this.CurrencyCode = null;
    }


}