<ng-content *ngIf="currentSector === 'E2E9FE9F-B245-4DE3-B341-0535CC97B45F' || currentSector === '088B3946-96DA-43A7-95A9-99BB7269D49C'; then person else company">
</ng-content>

<ng-template #person>
    <app-personal-desktop-contact #personForm 
    (notifySectorChange)="onSectorChange($event)"
    (save)="onSave($event)"
    [contactSectorId]="currentSector"></app-personal-desktop-contact>
</ng-template>

<ng-template #company>
    <app-business-desktop-contact #companyForm 
    (notifySectorChange)="onSectorChange($event)" 
    (save)="onSave($event)"
    [contactSectorId]="currentSector"></app-business-desktop-contact>
</ng-template>





