<igx-stepper #stepper class="stepper">
  <igx-step [hidden]="false">
    <div igxStepContent class="flex-col flex-gap-18 step">
      <strong>Obligatorisk informasjon:</strong>
      <!-- <div>
                <dlx-select [(ngModel)]="contactSectorId" [outputField]="'Id'" [id]="'contactSector'"
                    [inputLabel]="'Kontaktsektor:'" [itemLabel]="'Name'" [itemCode]="'Code'"
                    (valueReset)="onSectorReset()" (valueChange)="onSectorChange($event)" [dataSource]="contactSectors">
                </dlx-select>
            </div> -->

      <div class=" contact-split-section m-left simple-select-wrapper phone-select">
        <label #contactSectorLabel igxLabel
          class="simple-select-label simple-select-label--selected">Kontaktsektor:</label>
        <igx-simple-combo [data]="contactSectors" displayDensity="compact"
          (opening)="combo.comboOpening(contactSectorLabel)"
          (closing)="combo.comboClosing(contactSectorLabel, contactSectorId)" [displayKey]="'Name'" [valueKey]="'Id'"
          [(ngModel)]="contactSectorId" [type]="'border'" (selectionChanging)="onSectorSelectionChanging($event)">
        </igx-simple-combo>
      </div>

      <!-- <div>
                <dlx-select [(ngModel)]="countryCode" [outputField]="'CountryCode'" [id]="'countryId'"
                    [inputLabel]="'Land:'" [itemLabel]="'Country'" [itemCode]="'CountryCode'"
                    (valueReset)="onCountryReset()" (valueChange)="onCountryChange($event)" [dataSource]="countries">
                </dlx-select>
            </div> -->

      <div class=" contact-split-section m-left simple-select-wrapper phone-select">
        <label #countryLabel igxLabel class="simple-select-label simple-select-label--selected">Nasjonalitet:</label>
        <igx-simple-combo [data]="countries" displayDensity="compact" (opening)="combo.comboOpening(countryLabel)"
          (closing)="combo.comboClosing(countryLabel, countryCode)" [displayKey]="'Nationality'" [valueKey]="'Code'"
          [(ngModel)]="countryCode" [type]="'border'" (ngModelChange)="onCountryChange($event)">
        </igx-simple-combo>
      </div>
      <div>

        <igx-input-group displayDensity="compact" type="border">
          <input igxInput name="newContactFirstName" type="text" [(ngModel)]="firstName" />
          <label igxLabel for="newContactFirstName">Fornavn:</label>
        </igx-input-group>
      </div>
      <div>

        <igx-input-group displayDensity="compact" type="border">
          <input igxInput name="newContactSurName" type="text" [(ngModel)]="lastName" />
          <label igxLabel for="newContactSurName">Etternavn:</label>
        </igx-input-group>
      </div>
    </div>
  </igx-step>
  <igx-step [hidden]="false">
    <div igxStepContent class="flex-col flex-gap-18 step">
      <strong>Kontakt informasjon:</strong>
      <div>
        <igx-input-group displayDensity="compact" type="border">
          <input igxInput name="newContactPhone" type="number" [(ngModel)]="phone" />
          <label igxLabel for="newContactPhone">Telefon:</label>
        </igx-input-group>
      </div>
      <div>

        <igx-input-group displayDensity="compact" type="border">
          <input igxInput name="newContactEmail" type="email" [(ngModel)]="email" />
          <label igxLabel for="newContactEmail">E-post:</label>
        </igx-input-group>
      </div>
      <div>
        <igx-input-group displayDensity="compact" type="border">
          <input name="newAddress" igxInput autocomplete="off" type="text" [(ngModel)]="address"
            style="resize: none;margin-bottom: 18px;" />
          <label igxLabel for="newAddress">Postadresse:</label>
        </igx-input-group>
      </div>
      <div class="flex-row postcode-input">
        <igx-input-group style="width: 80px;" displayDensity="compact" type="border">
          <input [disabled]="!address" [maxlength]="4" igxInput name="newContactPostCode" type="string"
            [(ngModel)]="postCode" />
          <label igxLabel for="newContactPostCode">Postnr:</label>
        </igx-input-group>
        <span class="postOffice">{{postOffice || 'Poststed'}}</span>
      </div>
    </div>
  </igx-step>
  <igx-step [hidden]="false">
    <div igxStepContent class="flex-col flex-gap-18 step">
      <strong>Annen informasjon:</strong>
      <div class="checkbox">
        <input [(ngModel)]="vatObligated" type="checkbox" name="avgiftspliktig" id="avgiftspliktig">
        <label for="avgiftspliktig">Avgiftspliktig</label>
      </div>
      <div class="checkbox">
        <input #useInvoiceEmailCB [(ngModel)]="useInvoiceEmail" type="checkbox" name="useInvoiceEmail"
          id="useInvoiceEmail">
        <label for="useInvoiceEmail">Send faktura som E-post</label>
      </div>
      <div *ngIf="useInvoiceEmail" class="checkbox indented">
        <input [(ngModel)]="useSameAsContactEmail" type="checkbox" name="invoiceSameAsEmail" id="invoiceSameAsEmail">
        <label for="invoiceSameAsEmail">Bruk samme som kontaktepost</label>
      </div>
      <div class="checkbox">
        <input #useInvoiceDigiDB [(ngModel)]="useInvoiceDigipost" type="checkbox" name="invoiceDigi" id="invoiceDigi">
        <label for="invoiceDigi">Send faktura med Digipost</label>
      </div>
      <div *ngIf="useInvoiceEmail">
        <igx-input-group displayDensity="compact" type="border">
          @if(useSameAsContactEmail) {
          <input igxInput name="newContactEmailInvoice" type="text" [(ngModel)]="email" />
          } @else {
          <input igxInput name="newContactEmailInvoice" type="text" [(ngModel)]="invoiceEmail" />
          }
          <label igxLabel for="newContactEmailInvoice">E-post faktura:</label>
        </igx-input-group>
      </div>
      <div>
        <igx-input-group *ngIf="contactSectorId  === '088B3946-96DA-43A7-95A9-99BB7269D49C'" displayDensity="compact"
          type="border">
          <input igxInput name="newContactOrgNumber" type="text" [(ngModel)]="orgNumber" />
          <label igxLabel for="newContactOrgNumber">Org.nummer:</label>
        </igx-input-group>
      </div>
    </div>
  </igx-step>
</igx-stepper>


<div class="flex-col component-structure newContactFooter">
  <div class="flex-row">
    <ng-content *ngIf="step > 1; then prevBtn else prevBtnDisabled" />
    <ng-content *ngIf="firstName && lastName && country && contactSectorId; then saveBtn else saveBtnDisabled" />
    <ng-content *ngIf="step < 3; then nextBtn else nextBtnDisabled" />
  </div>
</div>



<ng-template #saveBtn>
  <button class="newContactSaveBtn" igxButton="raised" igxButtonColor="white" [style.background]="'#AEC965'"
    igxRipple="white" (click)="onSave()">LAGRE</button>
</ng-template>
<ng-template #saveBtnDisabled>
  <button class="newContactSaveBtn" igxButton="raised" [disabled]="'true'">LAGRE</button>
</ng-template>

<ng-template #nextBtn>
  <button class="newContactBtn" igxButton="raised" igxButtonColor="white" [style.background]="'#5e5e5e'"
    igxRipple="white" (click)="onNext()">
    <span class="material-symbols-outlined">chevron_right</span>
  </button>
</ng-template>
<ng-template #nextBtnDisabled>
  <button class="newContactBtn" igxButton="raised" [disabled]="'true'">
    <span class="material-symbols-outlined">chevron_right</span>
  </button>
</ng-template>

<ng-template #prevBtn>
  <button class="newContactBtn" igxButton="raised" igxButtonColor="white" [style.background]="'#5e5e5e'"
    igxRipple="white" (click)="onPrev()">
    <span class="material-symbols-outlined">chevron_left</span>
  </button>
</ng-template>
<ng-template #prevBtnDisabled>
  <button class="newContactBtn" igxButton="raised" [disabled]="'true'">
    <span class="material-symbols-outlined">chevron_left</span>
  </button>
</ng-template>