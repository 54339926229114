import { AfterViewChecked, ChangeDetectorRef, Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DatalexClient, IContactBE } from '@datalex-software-as/datalex-client';
import { ScreenDimensionService } from 'src/app/services/screen-dimension.service';
import { ContactSectorIdEnum } from 'src/app/util/ContactSectorUtil';
import { BusinessContactComponent } from './business-contact/business-contact.component';
import { PersonalContactComponent } from './personal-contact/personal-contact.component';
import { NgIf } from '@angular/common';

@Component({
    selector: 'new-contact-mobile',
    templateUrl: './new-contact-mobile.component.html',
    styleUrls: ['./new-contact-mobile.component.scss'],
    standalone: true,
    imports: [NgIf, PersonalContactComponent, BusinessContactComponent]
})
export class NewContactMobileComponent implements OnInit, AfterViewChecked  {


  constructor(private dlxClient: DatalexClient, private router: Router, public screen: ScreenDimensionService, private change: ChangeDetectorRef) { }
  
  onSectorChange(e: string) {
    this.newSector = e;
  }

  @Output() save: EventEmitter<IContactBE> = new EventEmitter();

  newSector!: string;
  currentSector: string = ContactSectorIdEnum.P;

  @ViewChild('companyForm') company!: BusinessContactComponent;
  @ViewChild('personForm') person!: PersonalContactComponent;

  ngOnInit(): void {
    //throw new Error('Method not implemented.');
  }

  ngAfterViewChecked() {
    if(typeof this.newSector === 'undefined') return
    if(this.currentSector !== this.newSector) {
      this.currentSector = this.newSector;      
      this.change.detectChanges();
    }
        
  }

  onSave(contactBe: IContactBE){
    this.save.emit(contactBe)
  }

}