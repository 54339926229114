import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { DatalexClient, IContactBE } from '@datalex-software-as/datalex-client';
import { ScreenDimensionService } from 'src/app/services/screen-dimension.service';
import { NewContactDesktopComponent } from './new-contact-desktop/new-contact-desktop.component';
import { NewContactMobileComponent } from './new-contact-mobile/new-contact-mobile.component';
import { NgIf } from '@angular/common';

interface navParams { sender: string, id: string }

@Component({
  selector: 'app-new-contact',
  templateUrl: './new-contact.component.html',
  styleUrls: ['./new-contact.component.scss'],
  standalone: true,
  imports: [NgIf, NewContactMobileComponent, NewContactDesktopComponent]
})
export class NewContactComponent implements OnInit {
  constructor(public screen: ScreenDimensionService, private dlxClient: DatalexClient, private router: Router, private aRoute: ActivatedRoute) { }

  ngOnInit(): void {

  }

  onSave(contactBe: IContactBE) {
    contactBe.OriginCode = "WEB";
    if (!contactBe.OrganizationNumber) {
      contactBe.OrganizationNumber = null
    }

    console.log(contactBe);

    this.dlxClient.SaveContact(contactBe).subscribe({
      next: (res) => {

        this.aRoute.queryParams.subscribe({
          next: (params: Params) => {
            if (Object.keys(params).length !== 0) {
              this.router.navigate([`${params['sender']}/${params['id']}`], { queryParams: { sender: 'newcontact', id: res.Id, rkid: params['rkid'], rid: params['rid'], cdt: params['cdt'] } })
            } else {
              this.router.navigate([`contact/${res.Id}`])
            }
          }
        })
      }
    })


  }
}
