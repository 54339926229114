<div class="title flex-row component-structure contact-header">
  <div class="flex-col">
    <strong class="main-title">Ny kontakt</strong>
    <strong class="sub-title">{{contactSectorType}}</strong>
  </div>
  <div class="saveButton-col">
    <ng-content *ngIf="firstName && lastName && country && contactSectorId; then saveBtn else saveBtnDisabled">
    </ng-content>
  </div>
</div>
<div class="content-wrapper">

  <div class="component-structure newContactLeft">
    <strong>Personinformasjon</strong>
    <div class="form-wrapper">
      <div class="div1">
        <igx-input-group displayDensity="compact" type="border">
          <input igxInput name="contactNumber" type="number" [(ngModel)]="contactNumber" [disabled]="true" />
          <label igxLabel for="contactNumber">kontaktnr:</label>
        </igx-input-group>
      </div>

      <!-- <div class=" ">
                <dlx-select [(ngModel)]="contactSectorId" [outputField]="'Id'" [id]="'contactSector'"
                    [inputLabel]="'Kontaktsektor:'" [itemLabel]="'Name'" [itemCode]="'Code'"
                    (valueReset)="onSectorReset()" (valueChange)="onSectorChange($event)" [dataSource]="contactSectors">
                </dlx-select>
            </div> -->

      <div class="div2 simple-select-wrapper">
        <label #contactSectorLabel igxLabel
          class="simple-select-label simple-select-label--selected">Kontaktsektor:</label>
        <igx-simple-combo [data]="contactSectors" displayDensity="compact"
          (opening)="combo.comboOpening(contactSectorLabel)"
          (closing)="combo.comboClosing(contactSectorLabel, contactSectorId)" [displayKey]="'Name'" [valueKey]="'Id'"
          [(ngModel)]="contactSectorId" [type]="'border'" (selectionChanging)="onSectorSelectionChanging($event)">
        </igx-simple-combo>
      </div>

      <div *ngIf="allEmployees" class=" div3   simple-select-wrapper ">
        <label #responsibleLabel igxLabel
          class="simple-select-label simple-select-label--selected">Klientansvarlig:</label>
        <igx-simple-combo id="clientResponsible" class="simple-select-disable-reset" [data]="allEmployees"
          displayDensity="compact" (opening)="combo.comboOpening(responsibleLabel)"
          (closing)="combo.comboClosing(responsibleLabel, clientResponsible?.Id)" [displayKey]="'ContactName'"
          [(ngModel)]="clientResponsible" [type]="'border'" (ngModelChange)="onClientResponsibleChange($event)">
        </igx-simple-combo>
      </div>

      <div class="div4">
        <igx-input-group displayDensity="compact" type="border">
          <input igxInput name="newContactFirstName" type="text" [(ngModel)]="firstName" />
          <label igxLabel for="newContactFirstName">Fornavn:</label>
        </igx-input-group>
      </div>
      <div class="div5">
        <igx-input-group displayDensity="compact" type="border">
          <input igxInput name="newLastName" type="text" [(ngModel)]="lastName" />
          <label igxLabel for="newLastName">Etternavn:</label>
        </igx-input-group>
      </div>

      <div class="div6">
        <igx-input-group displayDensity="compact" type="border">
          <input igxInput name="newContactName2" type="text" [(ngModel)]="name2" />
          <label igxLabel for="newContactName2">Navn 2:</label>
        </igx-input-group>
      </div>
      <div class="div7">
        <igx-input-group displayDensity="compact" type="border">
          <input igxInput name="newContacPsuedo" type="text" [(ngModel)]="psuedo" />
          <label igxLabel for="newContacPsuedo">Psuedo:</label>
        </igx-input-group>
      </div>

      <div class="div8">
        <igx-input-group displayDensity="compact" type="border">
          <input igxInput name="newContactOccupation" type="text" [(ngModel)]="occupation" />
          <label igxLabel for="newContactOccupation">Yrke:</label>
        </igx-input-group>
      </div>

      <div class="div9">
        <igx-input-group displayDensity="compact" type="border">
          <input igxInput name="newContactTitle" type="text" [(ngModel)]="title" />
          <label igxLabel for="newContactTitle">Tittel:</label>
        </igx-input-group>
      </div>

      <div class="div10">
        <igx-input-group displayDensity="compact" type="border">
          <input igxInput name="newContactInitials" type="text" [(ngModel)]="initials" />
          <label igxLabel for="newContactInitials">Initialer:</label>
        </igx-input-group>
      </div>
      <!-- <div class=" ">
                <dlx-select [(ngModel)]="countryCode" [outputField]="'CountryCode'" [id]="'countryId'"
                    [inputLabel]="'Land:'" [itemLabel]="'Country'" [itemCode]="'CountryCode'"
                    (valueReset)="onCountryReset()" (valueChange)="onCountryChange($event)" [dataSource]="countries">
                </dlx-select>
            </div> -->

      <div class="div11 simple-select-wrapper">
        <label #countryLabel igxLabel class="simple-select-label simple-select-label--selected">Nasjonalitet:</label>
        <igx-simple-combo [data]="countries" displayDensity="compact" (opening)="combo.comboOpening(countryLabel)"
          (closing)="combo.comboClosing(countryLabel, countryCode)" [displayKey]="'Nationality'" [valueKey]="'Code'"
          [(ngModel)]="countryCode" [type]="'border'" (ngModelChange)="onCountryChange($event)">
        </igx-simple-combo>
      </div>

      <div class="div12 simple-select-wrapper contact_gender">
        <label #genderLabel igxLabel class="simple-select-label simple-select-label--selected">Kjønn:</label>
        <igx-simple-combo [data]="genders" displayDensity="compact" [displayKey]="'label'" [valueKey]="'value'"
          [(ngModel)]="contactGender" [type]="'border'">
        </igx-simple-combo>
      </div>

      <div class="div13">
        <igx-input-group displayDensity="compact" type="border">
          <input igxInput name="newContactNationalIdentityNumber" type="text" [(ngModel)]="nationalIdentityNumber"
            appBirthDayNumberValidator #nationalIdentityNumberCtrl="ngModel" minlength="6" maxlength="11"
            (blur)="inputBlurred=true" (focus)="inputBlurred=false" />
          <label igxLabel for="newContactNationalIdentityNumber">Fødselsnummer:</label>
        </igx-input-group>
        <div *ngIf="inputBlurred && nationalIdentityNumberCtrl.errors && nationalIdentityNumberCtrl.touched"
          class="error-message">
          * Fødselsnummeret er ugyldig.
        </div>
      </div>

      <div class="div14">
        <igx-date-picker displayDensity="compact" [inputFormat]="'dd.MM.yyyy'" [displayFormat]="'dd.MM.yy'"
          [(ngModel)]="dateOfBirth" type="border">
          <label igxLabel>Fødselsdato:</label>
        </igx-date-picker>
      </div>

      <div *ngIf="contactSectorId === '088B3946-96DA-43A7-95A9-99BB7269D49C'" class="div15">
        <igx-input-group displayDensity="compact" type="border">
          <input igxInput name="newContactOrganizationNumber" type="number" [(ngModel)]="organizationNumber" />
          <label igxLabel for="newContactOrganizationNumber">Org.nummer:</label>
        </igx-input-group>
      </div>
    </div>
  </div>

  <div class="component-structure newContactRight">
    <strong>Telefon</strong>
    <div class="phone-wrapper">
      <div class="phoneType  simple-select-wrapper">
        <label #tlfTypeLabel igxLabel class="simple-select-label simple-select-label--selected">Telefontype:</label>
        <igx-simple-combo [data]="phoneNumberTypes" displayDensity="compact"
          (opening)="combo.comboOpening(tlfTypeLabel)" (closing)="combo.comboClosing(tlfTypeLabel, currentPhoneTypeId)"
          [displayKey]="'Name'" [valueKey]="'Id'" [(ngModel)]="currentPhoneTypeId" [type]="'border'"
          (ngModelChange)="onPhoneTypeChange($event)">
        </igx-simple-combo>
      </div>

      <div class="phoneNumber">
        <igx-input-group displayDensity="compact" type="border">
          <input igxInput name="currentPhoneNumber" type="number" [(ngModel)]="currentPhoneNumber"
            (ngModelChange)="onPhoneInputChange($event)" />
          <label igxLabel for="currentPhoneNumber">Telefonnummer:</label>
        </igx-input-group>
      </div>
    </div>

    <!-- <div class="m-top email-wrapper">
      <strong>E-post og web</strong>
      <igx-input-group displayDensity="compact" type="border">
        <input igxInput name="emailAddress" type="email" [(ngModel)]="emailAddress" />
        <label igxLabel for="emailAddress">E-post:</label>
      </igx-input-group>

      <igx-input-group displayDensity="compact" type="border">
        <input igxInput name="invoiceEmailAddress" type="email" [(ngModel)]="emailAddressInvoice" />
        <label igxLabel for="invoiceEmailAddress">E-post faktura:</label>
      </igx-input-group>

      <igx-input-group displayDensity="compact" type="border">
        <input igxInput name="webAddress" type="text" [(ngModel)]="webAddress" />
        <label igxLabel for="webAddress">Hjemmeside:</label>
      </igx-input-group>
    </div> -->


    <div class="m-top email-wrapper">
      <strong>E-post og web</strong>

      <div class="email-container">
        <igx-input-group displayDensity="compact" type="border">
          <input igxInput name="emailAddress" type="email" [(ngModel)]="emailAddress" appEmailValidator
            #emailAddressCtrl="ngModel" />
          <!-- <input igxInput name="emailAddress" type="email" [(ngModel)]="emailAddress" appEmailValidator
            (focus)="hideErrorMessage('emailAddress')" (blur)="showErrorMessageOnBlur('emailAddress')"
            #emailAddressCtrl="ngModel" /> -->
          <label igxLabel for="emailAddress">E-post:</label>
        </igx-input-group>
        <div *ngIf="showEmailErrorMessage && (emailAddressCtrl.errors?.['emailInvalid'] ?? false)"
          class="error-message">
          * Skriv inn en gyldig e-postadresse. Eks: ola.nordmann&#64;eksempel.no
        </div>
      </div>
      <div class="invoiceEmail-container">
        <igx-input-group displayDensity="compact" type="border">
          <input igxInput name="invoiceEmailAddress" type="email" [(ngModel)]="emailAddressInvoice" appEmailValidator
            (focus)="hideErrorMessage('emailAddressInvoice')" (blur)="showErrorMessageOnBlur('emailAddressInvoice')"
            #invoiceEmailAddressCtrl="ngModel" />
          <label igxLabel for="invoiceEmailAddress">E-post faktura:</label>
        </igx-input-group>
        <div *ngIf="showInvoiceEmailErrorMessage && (invoiceEmailAddressCtrl.errors?.['emailInvalid'] ?? false)"
          class="error-message">
          * Skriv inn en gyldig e-postadresse. Eks: ola.nordmann&#64;eksempel.no
        </div>
      </div>

      <igx-input-group displayDensity="compact" type="border">
        <input igxInput name="webAddress" type="text" [(ngModel)]="webAddress" />
        <label igxLabel for="webAddress">Hjemmeside:</label>
      </igx-input-group>
    </div>

    <div class="m-top">
      <strong>Adresser</strong>
      <div class="flex-row">
        <div class="radio-row m-top-s">

          <label for="post">
            <input #post (change)="addressRadioChange($event, post)" type="radio" name="addresstype" id="post" checked>
            <span>Postadresse</span>
          </label>


          <label for="visit">
            <input #visit (change)="addressRadioChange($event, visit)" type="radio" name="addresstype" id="visit">
            <span>Besøksadresse</span>
          </label>

          <label for="invoice">
            <input #invoice (change)="addressRadioChange($event, invoice)" type="radio" name="addresstype" id="invoice">
            <span>Fakturaadresse</span>
          </label>

          <label for="registered">
            <input #registered (change)="addressRadioChange($event, registered)" type="radio" name="addresstype"
              id="registered">
            <span>Folkeregisteradresse</span>
          </label>

        </div>
      </div>
      <div class="m-top" *ngIf="country">
        <div *ngIf="_activeRadio === 'post'">
          <dlx-address-input (notifyAddressChange)="setPostAddressObj($event)" [existingAddress]="postAddressObj"
            [country]="country" [countryCode]="countryCode" [addressType]="addressTypes.PostAddress">
          </dlx-address-input>
        </div>
        <div *ngIf="_activeRadio === 'visit'">
          <dlx-address-input (notifyAddressChange)="setVisitAddressObj($event)" [existingAddress]="visitAddressObj"
            [country]="country" [countryCode]="countryCode"
            [addressType]="addressTypes.VisitAddress"></dlx-address-input>
        </div>
        <div *ngIf="_activeRadio === 'invoice'">
          <dlx-address-input (notifyAddressChange)="setInvoiceAddressObj($event)" [existingAddress]="invoiceAddressObj"
            [country]="country" [countryCode]="countryCode"
            [addressType]="addressTypes.InvoiceAddress"></dlx-address-input>
        </div>
        <div *ngIf="_activeRadio === 'registered'">
          <dlx-address-input (notifyAddressChange)="setRegisteredAddressObj($event)"
            [existingAddress]="registeredAddressObj" [country]="country" [countryCode]="countryCode"
            [addressType]="addressTypes.RegisteredAddress"></dlx-address-input>
        </div>
      </div>
    </div>
    <div class="m-top">

      <strong>Annet</strong>
      <div class="flex-col cb-row m-top-s">
        <div class=" m-top-s">
          <input [(ngModel)]="vatObligated" type="checkbox" name="avgiftspliktig" id="avgiftspliktig">
          <label for="avgiftspliktig">Avgiftspliktig</label>
        </div>
        <div class=" m-top-s">
          <input #useInvoiceEmailCB [(ngModel)]="sendInvoiceAsEmail" type="checkbox" name="useInvoiceEmail"
            id="useInvoiceEmail" [indeterminate]="true">
          <label for="useInvoiceEmail">Send faktura som E-post</label>
        </div>
        <div class=" m-top-s">
          <input #useInvoiceDigiDB [(ngModel)]="sendInvoiceAsDigipost" type="checkbox" name="invoiceDigi"
            id="invoiceDigi" [indeterminate]="true">
          <label for="invoiceDigi">Send faktura med Digipost</label>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #saveBtn>
  <button class="newContactSaveBtn" igxButton="raised" igxButtonColor="white" [style.background]="'#AEC965'"
    igxRipple="white" (click)="validateAndAct()">LAGRE</button>
  <!-- <button class="newContactSaveBtn" id="submit" igxButton="raised" igxButtonColor="white" [style.background]="#AEC965"
    igxRipple="white" (click)="onSave()">LAGRE</button> -->
</ng-template>
<ng-template #saveBtnDisabled>
  <button class="newContactSaveBtn" igxButton="raised" [disabled]="'true'">LAGRE</button>
</ng-template>

<igx-dialog #nationalNumberErrorDialog title="Feil fødselsnummer"
  message="Du må taste inn minst fødselsdato (DDMMÅÅ), eller hele fødselsnummeret (11 siffer). Skal ikke inneholde bokstaver."
  leftButtonLabel="OK" (leftButtonSelect)="nationalNumberErrorDialog.close()">
</igx-dialog>