import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { IgxStepperComponent, IgxStepComponent, IgxStepContentDirective, IgxLabelDirective, IgxSimpleComboComponent, IgxInputGroupComponent, IgxInputDirective, IgxButtonDirective, IgxRippleDirective, ISimpleComboSelectionChangingEventArgs } from '@infragistics/igniteui-angular';
import { NewContactBE } from 'src/app/classes/NewContactBE';
import { DatalexClient, IContactBE, ICountryBE, IPostalAddressBE } from '@datalex-software-as/datalex-client';
import { ScreenDimensionService } from 'src/app/services/screen-dimension.service';
import { AddressTypeIdEnum } from 'src/app/util/AddressTypeUtil';
import { ContactSectorsArray, ContactSectorIdEnum } from 'src/app/util/ContactSectorUtil';
import { PhoneNumberTypeIdEnum } from 'src/app/util/PhoneNumberTypeUtil';
import { EmptyGuid } from 'src/app/util/RandomId';
import { CountryService } from 'src/app/services/country.service';
import { ComboSelectFunctionsService } from 'src/app/services/combo-select-functions.service';
import { NgIf } from '@angular/common';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-personal-contact',
  templateUrl: './personal-contact.component.html',
  styleUrls: ['./personal-contact.component.scss'],
  standalone: true,
  imports: [IgxStepperComponent, IgxStepComponent, IgxStepContentDirective, IgxLabelDirective, IgxSimpleComboComponent, FormsModule, IgxInputGroupComponent, IgxInputDirective, NgIf, IgxButtonDirective, IgxRippleDirective]
})
export class PersonalContactComponent implements OnInit {



  constructor(private dlxClient: DatalexClient, private countryData: CountryService, public screen: ScreenDimensionService, public combo: ComboSelectFunctionsService) { }

  @ViewChild('stepper') stepper!: IgxStepperComponent
  @ViewChild('useInvoiceEmailCB') useInvoiceEmailCB!: ElementRef<HTMLInputElement>
  @ViewChild('useInvoiceDigiDB') useInvoiceDigiDB!: ElementRef<HTMLInputElement>



  step: number = 1;

  contactSectors = ContactSectorsArray.filter(s => s.Id !== ContactSectorIdEnum.M);
  @Input() contactSectorId: string = ContactSectorIdEnum.P;

  @Output() notifySectorChange: EventEmitter<string> = new EventEmitter();
  @Output() save: EventEmitter<IContactBE> = new EventEmitter();

  onSectorReset() {

  }

  onSectorChange(e: string) {
    this.notifySectorChange.emit(e)
  }


  onSectorSelectionChanging(e: ISimpleComboSelectionChangingEventArgs): void {
    const { newSelection, newValue, oldValue } = e;
    const id = newSelection ? newSelection.Id : null;

    if (newValue === undefined || this.contactSectorId === id) {
      e.cancel = true;
      return;
    }

    this.contactSectorId = id;
    this.notifySectorChange.emit(id);
  }

  countryCode: string | null = "NO";
  countries = this.countryData.allCountries;
  onCountryChange(event: string) {
    this.dlxClient.GetCountryByCode(event).subscribe({
      next: (country) => {
        this.countryCode = event;
        this.country = country;
        if (country.Code.toLocaleUpperCase() !== "NO") {
          this.vatObligated = false;
        }

      }
    })
  }
  onCountryReset() {
    this.countryCode = null;
  }

  contactId: string = "000asdasd"

  country!: ICountryBE;
  _firstName!: string;
  set firstName(val: string) {
    this._firstName = val;
    if (this.firstName && this.lastName) {
      this.stepper.steps[0].completed = true;
    }
  }
  get firstName() {
    return this._firstName
  }

  _lastName!: string;
  set lastName(val: string) {
    this._lastName = val;
    if (this.firstName && this.lastName) {
      this.stepper.steps[0].completed = true;
    }
  }
  get lastName() {
    return this._lastName
  }

  name!: string;
  orgNumber!: string;

  phone!: string;
  email!: string;
  _address!: string;
  postAddress!: string;

  _postOffice!: string;

  set postOffice(val: string) {
    this._postOffice = val;
  }

  get postOffice() {
    return this._postOffice;
  }
  _postCode!: string;

  set postCode(val: string) {
    val = val.replace(/\D/g, '');


    this._postCode = val;
    if (!val || val === '') {
      this.postOffice = 'Poststed';
      return;
    }


    if (/^\d{4}$/.test(val)) {
      this.dlxClient.FindPostalAddresses(val, this.country.Id).subscribe({
        next: (e) => {
          this.postOffice = !!e[0] ? e[0].PostOffice : 'INGEN TREFF'
          this.postalAddress = e[0];
        }
      })
    }

  }

  get postCode() {
    return this._postCode;
  }

  set address(val: string) {
    this._address = val;
  }
  get address() {
    return this._address;
  }

  invoiceAddress!: string;
  postalAddress!: IPostalAddressBE
  homePage!: string;

  vatObligated: boolean = true;
  _useInvoiceEmail: boolean | null = null;
  useInvoceEmailState: number = 0;

  _useInvoiceDigipost: boolean | null = null;
  useInvoceDigiState: number = 0;


  set useInvoiceEmail(val: boolean | null) {
    this.checkboxStateMachine('useInvoiceEmailCB', 'useInvoceEmailState', '_useInvoiceEmail');
  }

  get useInvoiceEmail() {
    return this._useInvoiceEmail
  }


  set useInvoiceDigipost(val: boolean | null) {
    this.checkboxStateMachine('useInvoiceDigiDB', 'useInvoceDigiState', '_useInvoiceDigipost');
  }
  get useInvoiceDigipost() {
    return this._useInvoiceDigipost
  }
  useSameAsContactEmail = false;

  invoiceEmail: string | null = null;


  checkboxStateMachine(element: string, stateCounter: string, checkboxData: string) {
    //@ts-ignore
    this[stateCounter]++;
    //@ts-ignore
    switch (this[stateCounter]) {
      case 0:
        //@ts-ignore
        this[checkboxData] = null;
        //@ts-ignore
        this[element].nativeElement.indeterminate = true;
        break;
      case 1:
        //@ts-ignore
        this[checkboxData] = true;
        //@ts-ignore
        this[element].nativeElement.indeterminate = false;
        break;
      case 2:
      default:
        //@ts-ignore
        this[checkboxData] = false;
        //@ts-ignore
        this[element].nativeElement.indeterminate = false;
        //@ts-ignore
        this[stateCounter] = -1;
        break;
    }
  }


  ngOnInit(): void {

    this.dlxClient.GetCountryByCode('no').subscribe({
      next: (country) => {
        this.country = country;
        if (country.Code.toLocaleUpperCase() !== "NO") {
          this.vatObligated = false;
        }
      }
    })

  }

  ngAfterViewInit(): void {
    this.setCheckboxStates()
    this.stepper.steps[2].activeChange.subscribe(this.setCheckboxStates)
  }


  setCheckboxStates() {

    try {
      this.useInvoiceEmailCB.nativeElement.indeterminate = true;
      this.useInvoiceDigiDB.nativeElement.indeterminate = true;
    } catch (error) {
      error = null;
    }

  }

  onPrev() {
    this.stepper.prev()
    this.step--;
  }

  onNext() {
    this.stepper.next()
    this.step++;
  }


  // onAddressInput(event: Event) {
  //   if ((event as InputEvent).inputType.includes('delete')) return

  //   let textArray = this.address.split('\n');
  //   if (textArray.length === 1) return

  //   const pattern = /\d{4}/;
  //   if (pattern.test(this.address)) {
  //     let match = pattern.exec(textArray[textArray.length - 1])

  //     if (match && match.index === 0) {
  //       this.dlxClient.FindPostalAddresses(match.input, this.country.Id).subscribe({
  //         next: (e) => {
  //           this.address = this.address.replace(match!.input, `${!!e[0] ? e[0].PostCode : match} ${!!e[0] ? e[0].PostOffice : 'INGEN TREFF'}`)
  //           this.postalAddress = e[0];
  //         }
  //       })
  //     }

  //     textArray.splice(-1);

  //     this.postAddress = textArray.join(",");

  //   }
  // }

  onSave() {
    let contactBe = new NewContactBE({
      FirstName: this.firstName,
      LastName: this.lastName,
      Name: null,
      ContactSectorId: this.contactSectorId,
      Country: this.country
    })

    contactBe.VatObligated = this.vatObligated;
    contactBe.EmailInvoice = this.useInvoiceEmail;
    contactBe.EmailAddressInvoice = this.useSameAsContactEmail ? null : this.invoiceEmail;

    contactBe.WebAddress = this.homePage ?? null;
    contactBe.EmailAddress = this.email ?? null;
    contactBe.Phones = this.phone ? [{
      IsNew: true,
      IsDeleted: false,
      Id: EmptyGuid,
      ContactId: EmptyGuid,
      PhoneTypeId: PhoneNumberTypeIdEnum.Mobile,
      PhoneType: "",
      Number: this.phone,
      CountryCode: this.country.Code,
      Extension: null,
      Timestamp: []
    }] : [];

    if (this.postalAddress && this.address) {
      this.postAddress = `${this.address}, ${this.postalAddress.PostCode} ${this.postalAddress.PostOffice}`
    }

    contactBe.Addresses = this.postAddress ? [{
      IsDeleted: false,
      IsNew: true,
      Id: EmptyGuid,
      AddressTypeId: AddressTypeIdEnum.PostAddress,
      AddressType: "",
      ContactId: EmptyGuid,
      Address: this.postAddress,
      PostalId: this.postalAddress.Id,
      Timestamp: [],
      PostalAddress: this.postalAddress
    }] : [];

    contactBe.OrganizationNumber = this.orgNumber ?? null;

    this.save.emit(contactBe);
  }
}
