<div class="title flex-row component-structure contact-header">
  <div class="flex-col ">
    <strong class="main-title">Ny kontakt</strong>
    <strong class="sub-title">{{contactSectorType}}</strong>
  </div>
  <div class="saveButton-col">
    <ng-content *ngIf="name  && country && contactSectorId && isOrgNumberValid; then saveBtn else saveBtnDisabled">
    </ng-content>
  </div>
</div>
<div class="content-wrapper">
  <div class="component-structure newContactLeft">
    <strong>Bedriftsinformasjon</strong>
    <div class="form-wrapper">
      <div class="div1 ">
        <igx-input-group displayDensity="compact" type="border">
          <input igxInput name="contactNumber" type="number" [(ngModel)]="contactNumber" [disabled]="true" />
          <label igxLabel for="contactNumber">kontaktnr:</label>
        </igx-input-group>
      </div>
      <!-- <div class="div2 ">
                <dlx-select [(ngModel)]="contactSectorId" [outputField]="'Id'" [id]="'contactSector'"
                    [inputLabel]="'Kontaktsektor:'" [itemLabel]="'Name'" [itemCode]="'Code'"
                    (valueReset)="onSectorReset()" (valueChange)="onSectorChange($event)" [dataSource]="contactSectors">
                </dlx-select>
            </div> -->

      <div class="div2 simple-select-wrapper">
        <label #contactSectorLabel igxLabel
          class="simple-select-label simple-select-label--selected">Kontaktsektor:</label>
        <igx-simple-combo [data]="contactSectors" displayDensity="compact"
          (opening)="combo.comboOpening(contactSectorLabel)"
          (closing)="combo.comboClosing(contactSectorLabel, contactSectorId)" [displayKey]="'Name'" [valueKey]="'Id'"
          [(ngModel)]="contactSectorId" [type]="'border'" (selectionChanging)="onSectorSelectionChanging($event)">
        </igx-simple-combo>
      </div>

      <div *ngIf="allEmployees" class=" div3 simple-select-wrapper ">
        <label #responsibleLabel igxLabel
          class="simple-select-label simple-select-label--selected">Klientansvarlig:</label>
        <igx-simple-combo id="clientResponsible" class="simple-select-disable-reset" [data]="allEmployees"
          displayDensity="compact" (opening)="combo.comboOpening(responsibleLabel)"
          (closing)="combo.comboClosing(responsibleLabel, clientResponsible?.Id)" [displayKey]="'ContactName'"
          [(ngModel)]="clientResponsible" [type]="'border'" (ngModelChange)="onClientResponsibleChange($event)">
        </igx-simple-combo>
      </div>

      <div class="div4 ">
        <igx-input-group displayDensity="compact" type="border">
          <input igxInput name="newContactName" type="text" [(ngModel)]="name" />
          <label igxLabel for="newContactName">Navn:</label>
        </igx-input-group>
      </div>

      <div class="div5 ">
        <igx-input-group displayDensity="compact" type="border">
          <input igxInput name="newContactName2" type="text" [(ngModel)]="name2" />
          <label igxLabel for="newContactName2">Navn 2:</label>
        </igx-input-group>
      </div>

      <div class="div6 ">
        <igx-input-group displayDensity="compact" type="border">
          <input igxInput name="newContacPsuedo" type="text" [(ngModel)]="psuedo" />
          <label igxLabel for="newContacPsuedo">Psuedo:</label>
        </igx-input-group>
      </div>

      <div class="div7 ">
        <igx-input-group displayDensity="compact" type="border">
          <input igxInput name="newContactInitials" type="text" [(ngModel)]="initials" />
          <label igxLabel for="newContactInitials">Kortform:</label>
        </igx-input-group>
      </div>
      <div *ngIf="businessSectors" class="div8 simple-select-wrapper">
        <label #businessSectorLabel igxLabel class="simple-select-label simple-select-label--selected">Bransje:</label>
        <igx-simple-combo id="businessSector" [data]="businessSectors" displayDensity="compact"
          (opening)="combo.comboOpening(businessSectorLabel)"
          (closing)="combo.comboClosing(businessSectorLabel, businessSector!.Id)" [displayKey]="'Name'"
          [(ngModel)]="businessSector" [type]="'border'" (ngModelChange)="log(businessSector)">
        </igx-simple-combo>
      </div>

      <div class="div9 ">
        <igx-input-group displayDensity="compact" type="border">
          <input igxInput name="newContactOrganisationNumber" type="text" required [(ngModel)]="organisationNumber"
            (ngModelChange)="onOrganisationNumberChange($event)" pattern="^(NO)?\d{8,9}(MVA)?$" />
          <label igxLabel for="newContactOrganisationNumber">Org.nummer:</label>
        </igx-input-group>

      </div>
      <div class="div10 ">
        <div class=" simple-select-wrapper">
          <label #organisationalStructureLabel igxLabel
            class="simple-select-label simple-select-label--selected">Org.form:</label>
          <igx-simple-combo id="organisationalStructure" [data]="organisationalStructures" displayDensity="compact"
            (opening)="combo.comboOpening(organisationalStructureLabel)"
            (closing)="combo.comboClosing(organisationalStructureLabel, organisationalStructureId)" displayKey="Name"
            valueKey="Id" [(ngModel)]="organisationalStructureId" [type]="'border'">
          </igx-simple-combo>
        </div>
      </div>

      <div *ngIf="contactSectorId.toUpperCase() === '1321FC0D-718F-4300-9230-7D079348942B'" class="checkbox">
        <input [(ngModel)]="publicCompany" type="checkbox" name="publicCompany" id="publicCompany">
        <label for="invoiceDigi">Børsnotert</label>
      </div>
    </div>
  </div>
  <div class="component-structure newContactRight">
    <strong>Telefon</strong>
    <div class="phone-wrapper">
      <div class="phoneType  simple-select-wrapper">
        <label #tlfTypeLabel igxLabel class="simple-select-label simple-select-label--selected">Telefontype:</label>
        <igx-simple-combo [data]="phoneNumberTypes" displayDensity="compact"
          (opening)="combo.comboOpening(tlfTypeLabel)" (closing)="combo.comboClosing(tlfTypeLabel, currentPhoneTypeId)"
          [displayKey]="'Name'" [valueKey]="'Id'" [(ngModel)]="currentPhoneTypeId" [type]="'border'"
          (ngModelChange)="onPhoneTypeChange($event)">
        </igx-simple-combo>
      </div>

      <div class="phoneNumber">
        <igx-input-group displayDensity="compact" type="border">
          <input igxInput name="currentPhoneNumber" type="number" [(ngModel)]="currentPhoneNumber"
            (ngModelChange)="onPhoneInputChange($event)" />
          <label igxLabel for="currentPhoneNumber">Telefonnummer:</label>
        </igx-input-group>
      </div>
    </div>

    <div class="m-top email-wrapper">
      <strong>E-post og web</strong>
      <div class="email-container">
        <igx-input-group displayDensity="compact" type="border">
          <input igxInput name="emailAddress" type="email" [(ngModel)]="emailAddress" appEmailValidator
            (focus)="hideErrorMessage('emailAddress')" (blur)="showErrorMessageOnBlur('emailAddress')"
            #emailAddressCtrl="ngModel" />
          <label igxLabel for="emailAddress">E-post:</label>
        </igx-input-group>
        <div *ngIf="showEmailErrorMessage && (emailAddressCtrl.errors?.['emailInvalid'] ?? false)"
          class="error-message">
          * Skriv inn en gyldig e-postadresse. Eks: ola.nordmann&#64;eksempel.no
        </div>
      </div>

      <div class="invoiceEmail-container">
        <igx-input-group displayDensity="compact" type="border">
          <input igxInput name="invoiceEmailAddress" type="email" [(ngModel)]="emailAddressInvoice" appEmailValidator
            (focus)="hideErrorMessage('emailAddressInvoice')" (blur)="showErrorMessageOnBlur('emailAddressInvoice')"
            #invoiceEmailAddressCtrl="ngModel" />
          <label igxLabel for="invoiceEmailAddress">E-post faktura:</label>
        </igx-input-group>
        <div *ngIf="showInvoiceEmailErrorMessage && (invoiceEmailAddressCtrl.errors?.['emailInvalid'] ?? false)"
          class="error-message">
          * Skriv inn en gyldig e-postadresse. Eks: ola.nordmann&#64;eksempel.no
        </div>
      </div>

      <igx-input-group displayDensity="compact" type="border">
        <input igxInput name="webAddress" type="text" [(ngModel)]="webAddress" />
        <label igxLabel for="webAddress">Hjemmeside:</label>
      </igx-input-group>
    </div>

    <div class="m-top">
      <strong>Adresser</strong>
      <div class="flex-row">
        <div class="radio-row m-top-s">

          <label for="post">
            <input #post (change)="addressRadioChange($event, post)" type="radio" name="addresstype" id="post" checked>
            <span>Postadresse</span>
          </label>

          <label for="visit">
            <input #visit (change)="addressRadioChange($event, visit)" type="radio" name="addresstype" id="visit">
            <span>Besøksadresse</span>
          </label>

          <label for="invoice">
            <input #invoice (change)="addressRadioChange($event, invoice)" type="radio" name="addresstype" id="invoice">
            <span>Fakturaadresse</span>
          </label>

          <label for="registered">
            <input #registered (change)="addressRadioChange($event, registered)" type="radio" name="addresstype"
              id="registered">
            <span>Folkeregisteradresse</span>
          </label>

        </div>
      </div>
      <div class="m-top" *ngIf="country">
        <div *ngIf="_activeRadio === 'post'">
          <dlx-address-input (notifyAddressChange)="setPostAddressObj($event)" [existingAddress]="postAddressObj"
            [country]="country" [countryCode]="countryCode" [addressType]="addressTypes.PostAddress">
          </dlx-address-input>
        </div>
        <div *ngIf="_activeRadio === 'visit'">
          <dlx-address-input (notifyAddressChange)="setVisitAddressObj($event)" [existingAddress]="visitAddressObj"
            [country]="country" [countryCode]="countryCode"
            [addressType]="addressTypes.VisitAddress"></dlx-address-input>
        </div>
        <div *ngIf="_activeRadio === 'invoice'">
          <dlx-address-input (notifyAddressChange)="setInvoiceAddressObj($event)" [existingAddress]="invoiceAddressObj"
            [country]="country" [countryCode]="countryCode"
            [addressType]="addressTypes.InvoiceAddress"></dlx-address-input>
        </div>
        <div *ngIf="_activeRadio === 'registered'">
          <dlx-address-input (notifyAddressChange)="setRegisteredAddressObj($event)"
            [existingAddress]="registeredAddressObj" [country]="country" [countryCode]="countryCode"
            [addressType]="addressTypes.RegisteredAddress"></dlx-address-input>
        </div>
      </div>
      <div class="m-top">
        <strong>Annet</strong>
        <div class="flex-col cb-row m-top-s">
          <div class="m-top-s">
            <input [(ngModel)]="vatObligated" type="checkbox" name="avgiftspliktig" id="avgiftspliktig">
            <label for="avgiftspliktig">Avgiftspliktig</label>
          </div>
          <div class="m-top-s">
            <input #useInvoiceEmailCB [(ngModel)]="sendInvoiceAsEmail" type="checkbox" name="useInvoiceEmail"
              id="useInvoiceEmail" [indeterminate]="true">
            <label for="useInvoiceEmail">Send faktura som E-post</label>
          </div>
          <div class="m-top-s">
            <input #useInvoiceDigiDB [(ngModel)]="sendInvoiceAsDigipost" type="checkbox" name="invoiceDigi"
              id="invoiceDigi" [indeterminate]="true">
            <label for="invoiceDigi">Send faktura med Digipost</label>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ng-template #saveBtn>
    <button class="newContactSaveBtn" igxButton="raised" igxButtonColor="white" [style.background]="'#AEC965'"
      igxRipple="white" (click)="onSave()">LAGRE</button>
  </ng-template>
  <ng-template #saveBtnDisabled>
    <button class="newContactSaveBtn" igxButton="raised" [disabled]="'true'">LAGRE</button>
  </ng-template>