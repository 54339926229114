import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export class OrgNumberValidator {
  // Validator function for organization number
  static orgNumberValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;

      // Regular expression to match valid organization numbers
      const regex = /^(NO)?\d{8,9}(MVA)?$/;

      // Check if the value matches the regex
      const valid = regex.test(value);

      // Return null if valid, otherwise return an error object
      return valid ? null : { invalidOrgNumber: true };
    };
  }
}
